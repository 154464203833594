import _ from "lodash";
import { extractApiFailureMessage } from "../../../utils/api";

const defaultState = () => ({
    showNgWrapper: false,
    ngWrapperFullPane: false,
    ngReady: false,
    showWidget: true,
    appState: "loading",
    isLoadingServices: true,
    showGTCDataCollectionModal: false
});

/**
 appStates: loading, loaded, failed, setup-wizard, setup-proxy

 what is: anterior-setup-wizard, setup-server-registration-error, after-migration
 how is it possible to get: failed-reach-auth0
 */


export default (state = defaultState(), { type, payload }) => {
    switch (type) {
        case "APP:SHOW-NG-WRAPPER": {
            return { ...state, showNgWrapper: true, loadNgWrapper: true };
        }
        case "APP:HIDE-NG-WRAPPER": {
            return { ...state, showNgWrapper: false };
        }
        case "APP:NG-DE-INIT": {
            return { ...state, killNg: true, ngReady: false }
        }
        case "APP:NG-RE-INIT": {
            return { ...state, killNg: false }
        }
        case "APP:SUPPORT-SERVICES-SUCCESS": {
            return { ...state, supportServices: payload }
        }
        case "APP:ABOUT-SUCCESS": {
            return { ...state, about: payload }
        }
        case "APP:INSTANCE-PLACEMENT-SUCCESS": {
            return { ...state, instancePlacement: payload }
        }
        case "APP:INIT-SETUP-WIZARD": {
            return { ...state, appState: "setup-wizard", ngWrapperFullPane: true }
        }
        case "APP:INIT-PROXY": {
            return { ...state, appState: "proxy", ngWrapperFullPane: true }
        }
        case "APP:DARK-SITE-LOGIN": {
            return { ...state, appState: "dark-login" }
        }
        case "APP:DARK-SITE-LOGIN-PROGRESS": {
            return { ...state, appState: "dark-login-progress" }
        }
        case "APP:BOOT-SUCCESS": {
            return { ...state, appState: "ready" }
        }
        case "APP:BOOT-FAILED": {
            return { ...state, appState: "failed" }
        }
        case "APP:LOAD-SERVICES-SUCCESS": {
            const { services: servicesList, navJson } = payload;

            const canonicalResourceTypes = {};
            servicesList.forEach(service => {
                if(service.newKey && service.resourceTypes) {
                    for(const [resourceTypeKey, resourceTypeValue] of Object.entries(service.resourceTypes)) {
                        canonicalResourceTypes[service.newKey + "/" + resourceTypeKey] = resourceTypeValue;
                    }
                }
            });

            return { ...state, isLoadingServices: false, services: _.keyBy(servicesList, "key"), canonicalResourceTypes, servicesByNewKey: _.keyBy(servicesList, "newKey"), servicesList, navJson }
        }
        case "APP:LOAD-SERVICE-INTEGRATION-PATH": {
            const { services: servicesList, activeFeatures } = payload;
            const serviceIntegrationPaths = _.chain(servicesList).filter(service => {
                let enableServiceIntegration = undefined;
                if (service.uiIntegration?.features) {
                    for (let index = 0; index < service.uiIntegration.features.length; index++) {
                        let _featureId = service.uiIntegration.features[index]?.id;
                        if (!_.isUndefined(activeFeatures[`${_featureId}/serviceIntegration`])) {
                            enableServiceIntegration = activeFeatures[`${_featureId}/serviceIntegration`];
                            break;
                        }
                    }
                }
                return _.isUndefined(enableServiceIntegration) ? service.service?.isServiceIntegration : enableServiceIntegration;
            }).map("service.to").value();

            return { ...state, isLoadingServices: false, serviceIntegrationPaths }
        }
        case "APP:LOAD-SERVICES-ERROR": {
            return { ...state, isLoadingServices: false, appState: "failed" }
        }
        case "APP:ANGULAR-READY": {
            return { ...state, ngReady: true };
        }
        case "APP:OPEN-ACCOUNT-MANAGEMENT-WIDGET": {
            return { ...state, accountManagementWidgetOpen: true }
        }
        case "APP:CLOSE-ACCOUNT-MANAGEMENT-WIDGET": {
            return { ...state, accountManagementWidgetOpen: false }
        }
        case "APP:GET-AGENT-INFO-SUCCESS": {
            return { ...state, about: payload }
        }
        case "APP:GET-AGENT-INFO-FAILED": {
            return { ...state, aboutError: payload }
        }
        case "APP/RELOAD": {
            return { ...state, isReloading: true }
        }
        case "APP:UPDATE-GTC-DATA": {
            return { ...state, 
                showGTCDataCollectionModal: payload.showGTCDataCollectionModal,
                userName: payload.userName,
                userCompany: payload.userCompany
            };
        }
        case "APP:GET-GTC-DATA-FAILED": {
            return { ...state, showGTCDataCollectionModal: false }
        }
        case "APP:SAVE-GTC-DATA": {
            return { ...state, isGtcDataSubmitting: true }
        }
        case "APP:SAVE-GTC-DATA-SUCCESS": {
            return { ...state, isGtcDataSubmitting: false }
        }
        case "APP:SAVE-GTC-DATA-FAILED": {
            const gtcSaveError = extractApiFailureMessage(payload.error);
            return { ...state, isGtcDataSubmitting: false, gtcSaveError }
        }
        default: {
            return state;
        }
    }
};
