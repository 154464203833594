import _ from 'lodash';

export const getPermission = (rules, object, action = '') => {
    return rules.find(rule => rule?.startsWith(`bxp:${object}${action ? `-${action}` : ''}`))
}
export const isAllowed = (rules, object, action = '') => getPermission(rules, object, action) !== undefined;

export const isRole1 = member => (member?.role === 'Role-1')

export const isOrgAdmin = (rules) => {isAllowed(rules, 'organization', 'create') && isAllowed(rules, 'organization', 'modify')}

export const isOrganizationAdmin = (member, roles) => {
    if (member?.roles && roles) {
        const tenancyV4Roles = _.keyBy(roles, (role) => role.id);
        return _.filter(member.roles, (role) => _.toLower(tenancyV4Roles[role]?.description) === _.toLower('Organization Admin')).length > 0
    }
    return false
}