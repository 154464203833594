import classNames from "classnames";
import React, { forwardRef, useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { UnmountClosed } from 'react-collapse';
import ReactDom from "react-dom";
import InlineSVG from "react-inlinesvg";
import Measure from 'react-measure';
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { Transition } from "react-transition-group";
import { Tooltip, height, keys, useFocus, useHover } from "@netapp/bxp-design-system-react";
import { trackExternalLink } from "utils/mrTracker";
import { ReactComponent as CategoryChevron } from "./CategoryChevron.svg";
import { ReactComponent as FavouriteSelected } from "./FavouriteSelected.svg";
import { ReactComponent as FavouriteUnselected } from "./FavouriteUnselected.svg";
import styles from "./MainNav.module.scss";
import useMenuAccessibility from "./accessibility/Menu.a11y";

const SubMenu = ({ children, isAnimationFinished }) => {
    const refParent = useRef();
    const refChild = useRef();

    useLayoutEffect(() => {
        const parentNode = refParent.current.parentNode;
        const boundingRect = parentNode.getBoundingClientRect();

        refChild.current.style.top = `${boundingRect.top - 16}px`;
        refChild.current.style.left = `${boundingRect.left + boundingRect.width - 8}px`;
        refChild.current.style.zIndex = 2147483647;

    }, [isAnimationFinished]);

    return <div ref={refParent}>
        {ReactDom.createPortal(<div ref={refChild} className={styles['sub-tabs-container']}>
            {children}
        </div>, document.body)}
    </div>
};

const Service = ({ service, itemSelected, neverActive, isAnimationFinished }) => {
    const dispatch = useDispatch();
    const currentServiceRef = useRef(null);
    const isFavourited = useSelector(state => state.menu.favourites.byKey[service.key]);
    const { hoverParentProps, isHovered } = useHover();
    /************** For accessibility ****************/
    const { isFocused, onFocus, onBlur } = useFocus();
    const flyOutMenuRefs = useRef([]);
    const refsForHandlingKeyDownEvent = useRef([{}]);
    const toggleFavLabel = "Toggle favorite";
    /**
     * On press of "Esc" or "right arrow" key inside flyout menu, focus the submenu which triggered this flyout menu.
     */
    const flyOutMenuEventListeners = useMenuAccessibility(flyOutMenuRefs.current, refsForHandlingKeyDownEvent);
    const setFlyOutMenuRef = (index) => (ref) => {
        if (!ref) {
            return;
        }
        flyOutMenuRefs.current[index] = ref;
        refsForHandlingKeyDownEvent.current[index] = {};
        /**
         * On click of escape key or left arrow key from fly-out menu, service should be focussed.
         */
        refsForHandlingKeyDownEvent.current[index][keys.escape] = currentServiceRef.current;
        refsForHandlingKeyDownEvent.current[index][keys.left] = currentServiceRef.current;
        /**
         * If it's the last flyout menu, on click of tab button, focus the first flyout menu.
         */
        if (index + 1 === service.subTabs?.length) {
            refsForHandlingKeyDownEvent.current[index][keys.tab] = flyOutMenuRefs.current[0];
        }
    };

    // Invoked when a keydown event is triggered on a "Service" menu item.
    const onServiceKeyDown = (event) => {
        if (event) {
            if (event.keyCode === keys.right && flyOutMenuRefs?.current?.length) {
                flyOutMenuRefs.current[0].focus();
            } else if (event.keyCode === keys.enter || event.keyCode === keys.space) {
                // If we don't give timeout, the click event is not triggered.
                setTimeout(() => {
                    // Remove the focus from the service menu so that the left nav gets collapsed.
                    currentServiceRef?.current?.blur();
                });
            }
        }
    }

    // Invoked when a keydown event is triggered on a "Flyout" menu item.
    const onFlyoutMenuKeyDown = (event, menuIndex) => {
        if (event) {
            if (event.keyCode === keys.enter || event.keyCode === keys.space) {
                // If we don't give timeout, the default enter browser event behaviour to trigger click event is not working.
                setTimeout(() => {
                    // Remove the focus from the flyout menu so that the left nav gets collapsed.
                    flyOutMenuRefs?.current[menuIndex]?.blur();
                });
            } else {
                flyOutMenuEventListeners.onKeyDown(menuIndex, event);
            }
        }
    }
    /*************************************************/

    const toggleFavourite = () => {
        dispatch({
            type: isFavourited ? "MENU:UNSET-FAVOURITE" : "MENU:SET-FAVOURITE",
            payload: {
                serviceId: service.key
            }
        })
    }

    if (service.isExternal) {
        return <div className={styles['service']} {...hoverParentProps} >
            <a href={service.href + service.to}
               onClick={(e) => {
                   trackExternalLink({ category: "MainNav", label: service.key });
                   itemSelected(e);
               }}
               onMouseDown={e=>e.preventDefault()}
               data-nav={service.key}
            >
                <div className={styles.label}>
                    {service.name}
                </div>
            </a>
            <button onClick={toggleFavourite} aria-label={toggleFavLabel}>
                {isFavourited && <FavouriteSelected/>}
                {!isFavourited && <FavouriteUnselected/>}
            </button>
            {service.oldName && <Tooltip className={styles['also-known-as']}>Also known as "{service.oldName}"</Tooltip>}
        </div>
    } else {
        return <div className={styles['service']} {...hoverParentProps} onFocus={onFocus}
            onBlur={onBlur}
        >
            <NavLink
                innerRef={currentServiceRef}
                to={service.to}
                data-nav={service.key}
                activeClassName={neverActive ? undefined : styles['service-active']}
                onClick={itemSelected}
                onMouseDown={e=>e.preventDefault()}
                onKeyDown={onServiceKeyDown}
            >
                <div className={styles.label}>
                    {service.name}
                </div>
            </NavLink>
            <button onClick={toggleFavourite} aria-label={toggleFavLabel}>
                {isFavourited && <FavouriteSelected/>}
                {!isFavourited && <FavouriteUnselected/>}
            </button>
            {service.oldName && <Tooltip className={styles['also-known-as']}>Also known as "{service.oldName}"</Tooltip>}
            {service.subTabs && service.subTabs.length > 0 && (isHovered || isFocused) && isAnimationFinished && <SubMenu>
                {service.subTabs.map((subTab, index) => {
                    return <NavLink
                        onFocus={onFocus}
                        onKeyDown={(event) => onFlyoutMenuKeyDown(event, index)}
                        innerRef={setFlyOutMenuRef(index)}
                        to={service.to + subTab.to}
                        data-nav={service.key + `/${subTab.label}`}
                        className={styles['sub-tab']}
                        activeClassName={neverActive ? undefined : styles['sub-tab-active']}
                        onClick={itemSelected}
                        key={service.key + `/${subTab.label}`}
                        exact={!subTab.to}
                    >
                        {subTab.label}
                    </NavLink>
                })}
            </SubMenu>}
        </div>
    }
}

const Category = React.memo(({ category, isOpen, isActive, open, itemSelected, neverActive }) => {
    const [isAnimationFinished, setAnimationFinished] = useState(false);
    const categoryRef = useRef(null);
    const Icon = category.Icon;

    return <div>
        <button onClick={open} ref={categoryRef} className={classNames(styles.category, { [styles['category-open']]: isOpen, [styles['category-active']]: isActive })} data-nav-category={category.label}>
            <div className={styles.icon}>
                {Icon && <Icon />}
                {category.icon && <InlineSVG src={category.icon} />}
            </div>
            <div className={styles.label}>
                {category.label}
            </div>
            <CategoryChevron className={styles.chevron}/>
        </button>
        <UnmountClosed onWork={({ isFullyOpened }) => {
            setAnimationFinished(isFullyOpened);
        }} onRest={({ isFullyOpened }) => {
            setAnimationFinished(isFullyOpened);
        }} isOpened={isOpen} theme={{ collapse: height }}>
            {category.services.map(service => {
                return <Service key={service.key} service={service} itemSelected={itemSelected} neverActive={neverActive} isAnimationFinished={isAnimationFinished} />
            })}
        </UnmountClosed>
    </div>
})

const Favourites = React.memo(({ itemSelected, isOpen, open }) => {
    const favouritesList = useSelector(state => state.menu.favourites.list);
    const servicesByKey = useSelector(state => state.menu.servicesByKey);

    const category = {
        label: "Favorites",
        Icon: FavouriteSelected,
        services: favouritesList.map(favourite => {
            return servicesByKey[favourite];
        })
    };

    if (favouritesList.length === 0) {
        return "";
    }
    return <Category category={category} isOpen={isOpen} isActive={false} itemSelected={itemSelected} open={open} neverActive={true}/>
});

const MainNav = React.memo(forwardRef((props, lastNavRef) => {
    const ref = useRef(null);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [openCategory, setOpenCategory] = useState(null);
    const location = useLocation();
    const navItems = useSelector(state => state.menu.navItems);
    const servicesByTo = useSelector(state => state.menu.servicesByTo);
    const activeFeatures = useSelector(state => state.features.active);
    const [hasScroll, setHasScroll] = useState(false);
    const closeRef = useRef({});

    const activeCategory = useMemo(() => {
        if (!servicesByTo) {
            return;
        }

        const match = location.pathname.match(/(?<initialPath>\/[^/]+)/);
        if (!match?.groups?.initialPath) {
            return null;
        } else {
            const activeService = servicesByTo[match.groups.initialPath];
            return activeService?.category;
        }
    }, [location.pathname, servicesByTo]);

    const itemSelected = useCallback((e) => {
        if ((document.body.clientWidth < 1366 && e.clientX > 48) || e.clientX > 72) {
            setMenuOpen(false);
            setOpenCategory(null);
        }
    }, [setMenuOpen, setOpenCategory]);

    if (!navItems) {
        return <div/>;
    }

    const menuEnter = () => {
        if(closeRef.current.timeout) {
            clearTimeout(closeRef.current.timeout);
            closeRef.current.timeout = null;
        } else {
            setMenuOpen(true);
            setOpenCategory(closeRef.current.lastCategory || activeCategory);
        }
    }

    const menuLeave = () => {
        closeRef.current.timeout = setTimeout(() => {
            closeRef.current.timeout = null;
            closeRef.current.lastCategory = openCategory;
            setMenuOpen(false);
            setOpenCategory(null);

            setTimeout(() => {
                closeRef.current.lastCategory = null;
            }, 400)
        }, 200)
    }

    return <div className={styles.wrapper}>
        <Transition nodeRef={ref} in={isMenuOpen} timeout={400}>
            {state => {
                return <><div ref={ref}
                            className={classNames(styles.container, styles[state], { [styles['has-scroll']]: hasScroll })}
                            onMouseEnter={menuEnter}
                            onFocus={menuEnter}
                            onMouseLeave={menuLeave}
                            onBlur={menuLeave}
                >
                    <Measure
                        bounds
                        onResize={contentRect => {
                            setHasScroll(contentRect.bounds.height > ref?.current?.clientHeight);
                        }}
                    >
                        {({ measureRef }) => (
                            <div ref={measureRef}>
                                <Favourites itemSelected={itemSelected} isOpen={openCategory === "Favorites"} open={() => setOpenCategory(openCategory === "Favorites" ? null : "Favorites")}/>
                                {navItems.map((category, index) => <Category
                                    itemSelected={itemSelected}
                                    key={category.label}
                                    category={category}
                                    isActive={activeCategory === category.label}
                                    isOpen={isMenuOpen && (openCategory === category.label || activeFeatures.menuCategoriesOpen)}
                                    open={() => setOpenCategory(openCategory === category.label ? null : category.label)}
                                />)}
                            </div>
                        )}
                    </Measure>
                </div>
                <button ref={lastNavRef} tabIndex={-1} aria-label="Press tab key"></button></>
            }}
        </Transition>
    </div>
}));

export default MainNav;