import { occmClient, apiClient } from "utils/clients";
import axios from "axios";
import { setExternals } from "utils/externals";
import { getItemAnon, setItemAnon } from "utils/localstorage";
import _ from "lodash";

const HIDE_GTC_COLLECTION_DIALOG_KEY_PREFIX = 'hideGtcCollectionDialog';

export default ({ dispatch, getState }) => next => async action => {
    const { type, payload } = action;
    if (type === "APP:BOOT") {

        if (process.env.REACT_APP_IS_SAAS === "true") {
            setExternals({});

            dispatch({
                type: "APP:BOOT-SUCCESS"
            });

            dispatch({
                type: "AUTH:INITIALIZE",
                payload: {
                    domain: process.env.REACT_APP_AUTH0_DOMAIN,
                    clientId: process.env.REACT_APP_AUTH0_CLIENT,
                    audience: process.env.REACT_APP_AUTH0_AUDIENCE
                }
            });
        } else {
            try {
                const [{ data: supportedServices }, { data: about }, { data: instancePlacement }] = await Promise.all([occmClient.get("/occm/api/occm/system/support-services"),
                    occmClient.get("/occm/api/occm/system/about"),
                    occmClient.get("/occm/api/occm/system/occm-instance-placement")]);
                dispatch({
                    type: "APP:SUPPORT-SERVICES-SUCCESS",
                    payload: supportedServices
                });
                dispatch({
                    type: "APP:ABOUT-SUCCESS",
                    payload: about
                });
                dispatch({
                    type: "APP:INSTANCE-PLACEMENT-SUCCESS",
                    payload: instancePlacement
                });

                if (supportedServices.setupInfo && !supportedServices.setupInfo.isSetup && supportedServices.setupInfo.isPendingConnectivitySet) {
                    dispatch({
                        type: "APP:INIT-PROXY"
                    });
                } else {
                    //if there is still not auth0Information, you need to register

                    if (process.env.REACT_APP_IS_DARK !== "true") {

                        const { tenancyServiceInformation: { accountId }, portalService: { auth0Information } } = supportedServices;

                        setExternals({ supportServices: supportedServices });

                        if (!auth0Information || !auth0Information.clientId) {
                            try {
                                await occmClient.post("/occm/api/occm/setup-portal/register");
                                window.location.reload();
                            } catch (e) {
                                if (e.status === 409) {
                                    //CM is already registered
                                    window.location.reload();
                                } else {
                                    dispatch({
                                        type: "APP:BOOT-FAILED",
                                        payload: e
                                    })
                                }
                            }
                        } else {
                            dispatch({
                                type: "AUTH:INITIALIZE",
                                payload: {
                                    domain: auth0Information.domain,
                                    clientId: auth0Information.clientId,
                                    audience: auth0Information.audience
                                }
                            });
                            if (supportedServices.setupInfo && !supportedServices.setupInfo.isSetup) {
                                dispatch({
                                    type: "APP:INIT-SETUP-WIZARD"
                                });
                            } else if (supportedServices.setupInfo && supportedServices.setupInfo.isSetup && supportedServices.setupInfo.isPendingConnectivitySet) {
                                dispatch({
                                    type: "APP:INIT-PROXY"
                                });
                            } else {
                                dispatch({
                                    type: "APP:GET-AGENT-INFO"
                                });

                                dispatch({
                                    type: "APP:BOOT-SUCCESS",
                                    payload: {
                                        accountId,
                                        clientId: auth0Information.clientId
                                    }
                                });
                            }
                        }

                    } else {
                        const { tenancyServiceInformation: { accountId }, portalService: { auth0Information } } = supportedServices;

                        setExternals({ supportServices: supportedServices });
                        if (supportedServices.setupInfo && supportedServices.setupInfo.needCertificate) {
                            dispatch({
                                type: "APP:INIT-PROXY"
                            });
                            dispatch({
                                type: "APP:INIT-DARK-SITE-PROXY-SSL-REQUIRED"
                            })
                        } else if (supportedServices.setupInfo && !supportedServices.setupInfo.isSetup) {
                            dispatch({
                                type: "APP:INIT-SETUP-WIZARD"
                            });
                        } else {
                            dispatch({
                                type: 'AUTH:INITIALIZE'
                            });
                            if (supportedServices.setupInfo && supportedServices.setupInfo.isSetup && supportedServices.setupInfo.isPendingConnectivitySet) {
                                dispatch({
                                    type: "APP:INIT-PROXY"
                                });
                            } else {
                                dispatch({
                                    type: "APP:GET-AGENT-INFO"
                                });
                                dispatch({
                                    type: "APP:BOOT-SUCCESS",
                                    payload: {
                                        accountId,
                                        clientId: auth0Information.clientId
                                    }
                                });
                            }
                        }
                    }
                }

            } catch (e) {
                dispatch({
                    type: "APP:BOOT-FAILED",
                    payload: e
                })
            }
        }
    } else if (type === "APP:HANDLE-GTC-CHECK") {
        const state = getState();
        const { userId } = state.auth;
        const hideGtcCollectionDialog = !!getItemAnon({ itemKey: `${HIDE_GTC_COLLECTION_DIALOG_KEY_PREFIX}_${userId}` });
        if (hideGtcCollectionDialog) {
            dispatch({
                type: "APP:UPDATE-GTC-DATA",
                payload: {
                    showGTCDataCollectionModal: false
                }
            });
        }
        else {
            const { isFederatedUser, isNetAppInternal, email } = state.auth;
            const isNssUser = userId.toLowerCase().includes('|bluexp-federation|');
            if(isFederatedUser && !isNssUser && !isNetAppInternal && !email.endsWith('@netapp.com')) {
                dispatch({
                    type: "APP:GET-GTC-DATA",
                    payload: { userId }
                });
            } else {
                setItemAnon({itemKey: `${HIDE_GTC_COLLECTION_DIALOG_KEY_PREFIX}_${userId}`}, true);
                dispatch({
                    type: "APP:UPDATE-GTC-DATA",
                    payload: {
                        showGTCDataCollectionModal: false
                    }
                });
            }
        }
    } else if (type === "APP:GET-GTC-DATA") {
        const base64UserId = window.btoa(payload.userId);
        apiClient.get(`${process.env.REACT_APP_OCCM_ENDPOINT}/v1/NetApp.identity/users/${base64UserId}`)
            .then(response => {
                const userData = response?.data || {};
                const businessAddress = _.get(userData, 'user_metadata.businessAddress', undefined);
                const userName = _.get(userData, 'name', undefined);
                const userCompany = _.get(userData, 'user_metadata.company', undefined);
                const gtcSubmitted  = _.get(userData, 'app_metadata.gtcSubmitted', false);
                const userCreationTime = new Date(_.get(userData, 'created_at', undefined));
                 //Aug 07 2024 - Federated user data collection for gtc start date
                 const minCreationTime = new Date("08/07/2024");
                const showModalBasedOnDate = userCreationTime > minCreationTime;
                const showGTCDataCollectionModal = showModalBasedOnDate && _.isEmpty(businessAddress) && !gtcSubmitted;

                if (!showGTCDataCollectionModal) {
                    setItemAnon({itemKey: `${HIDE_GTC_COLLECTION_DIALOG_KEY_PREFIX}_${payload.userId}`}, true);
                }

                dispatch({
                    type: "APP:UPDATE-GTC-DATA",
                    payload: {
                        showGTCDataCollectionModal,
                        userName,
                        userCompany
                    }
                });
            })
            .catch(error => {
                console.error(error);
                dispatch({ type: "APP:GET-GTC-DATA-FAILED" });
            });
    } else if (type === "APP:SAVE-GTC-DATA") {
        const base64UserId = window.btoa(payload.userId);
        const formData = payload.formData;
        apiClient.put(`${process.env.REACT_APP_OCCM_ENDPOINT}/v1/NetApp.identity/compliancecheck/${base64UserId}`, formData)
            .then((result) => {
                setItemAnon({itemKey: `${HIDE_GTC_COLLECTION_DIALOG_KEY_PREFIX}_${payload.userId}`}, true);
                dispatch({ type: "APP:SAVE-GTC-DATA-SUCCESS" });
                dispatch({
                    type: "APP:UPDATE-GTC-DATA",
                    payload: {
                        showGTCDataCollectionModal: false
                    }
                });
            }).catch((error) => {
                console.error(error);
                dispatch({
                    type: "APP:SAVE-GTC-DATA-FAILED",
                    payload: { error }
                });
            });
    } else if (type === "APP:LOAD-SERVICES") {
        let p;
        if (process.env.REACT_APP_SERVICES_ARRAY_ENDPOINT) {
            p = Promise.all([
                axios.get(process.env.REACT_APP_SERVICES_ARRAY_ENDPOINT + "/services.json").then(({ data }) => data),
                axios.get(process.env.REACT_APP_SERVICES_ARRAY_ENDPOINT + "/nav.json").then(({ data }) => data)
            ]);
        } else {
            p = Promise.all([
                import("ui-services/build/services").then(module => module.default),
                import("ui-services/build/nav").then(module => module.default)
            ])
        }

        try {
            let [services, navJson] = await p;

            dispatch({
                type: "APP:LOAD-SERVICES-SUCCESS",
                payload: {
                    services,
                    navJson
                }
            })
        } catch (e) {
            dispatch({
                type: "APP:LOAD-SERVICES-ERROR",
                payload: e
            })
        }
    } else if (type === "APP:GET-AGENT-INFO") {
        try {
            const { data: about } = await occmClient.get("/occm/api/occm/system/about");
            dispatch({
                type: "APP:GET-AGENT-INFO-SUCCESS",
                payload: about
            })
        } catch (err) {
            dispatch({
                type: "APP:GET-AGENT-INFO-FAILED",
                payload: err
            })
        }

    } else if (type === "APP:INITIALIZE-FEATURES") {
        const { supportServices, servicesList } = getState().app;
        setExternals({ servicesList });

        dispatch({
            type: "FEATURES:INITIALIZE",
            payload: {
                services: servicesList,
                supportServices: supportServices
            }
        })
        dispatch({
            type: "APP:LOAD-SERVICE-INTEGRATION-PATH",
            payload: {
                services: servicesList,
                activeFeatures: getState().features.active
            }
        });
    } else if (type === "MENU:INITIALIZE") {
        const state = getState();
        const { active: activeFeatures } = state.features;
        const { servicesByNewKey, navJson } = state.app;

        action.payload = {
            activeFeatures,
            servicesByNewKey,
            navJson
        }
    } else if (type === "APP:BOOT-FAILED" || type === "APP:LOAD-SERVICES-ERROR") {
        dispatch({
            type: "NOTIFICATIONS:ERROR",
            payload: {
                message: "Failed to initialize UI.",
                exception: payload,
                isFatal: true
            }
        })
    } else if (type === "SUPPORT:ADD-NSS-OPEN-POPOVER") {
        /**
         * Note: Most lazy loading occurs based on routes, but since services can dispatch to Add NSS even before having been to support dashboard, we will also lazy load on this action
         */
        const state = getState();
        if (!state.support) {
            import('modules/support' /* webpackChunkName: "Support"*/).then(r => {
                dispatch(action);
            })
            return next({ type: "IGNORE" });
        }
    } else if (type === "APP/RELOAD") {
        window.location.reload();
    }

    return next(action);

}