import { ReactComponent as AccountIsNowOrganization } from "./images/account-is-now-organization.svg";
import { ReactComponent as ControlAccessFromOnePlace } from "./images/control-access-from-one-place.svg";
import { ReactComponent as OrganizationHierarchy } from "./images/organization-hierarchy.svg";
import { ReactComponent as Resources } from "./images/resources.svg";
import { ReactComponent as Users } from "./images/users.svg";
import { ReactComponent as WorkspaceRenamedToProject } from "./images/workspace-renamed-to-project.svg";

export const whatsNewConfig = [
    {
        title: "Account is now organization",
        content: "We've renamed account to organization to help you easily visualize resource hierarchy in your tenancy.",
        img: <AccountIsNowOrganization />
    },
    {
        title: "Workspace renamed to project",
        content: "To accommodate for the Folder and Project construct in the new Resource Hierarchy, Workspace has been renamed to Project. ​",
        img: <WorkspaceRenamedToProject />
    },
    {
        title: "Control access from one place",
        content: "Get fine-tuned control over access to resources using the new identity and access management service.",
        img: <ControlAccessFromOnePlace />
    }
];

export const iAMWhatsNewConfig = [
    {
        title: "Organization hierarchy",
        content: "The organization hierarchy lets you easily view and manage your scopes, members, and resources.",
        img: <OrganizationHierarchy />
    },
    {
        title: "Users",
        content: "You can invite users or provide access to service applications as members of your organization. ​",
        img: <Users />
    },
    {
        title: "Resources",
        content: "See discovered & undiscovered resources. Associate resources with projects and folders to manage member access.",
        img: <Resources />
    }
];