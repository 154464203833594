import _ from "lodash";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { 
    Button,
    DropdownButton,
    MenuPopover,
    FormFieldSelect,
    Text } from "@netapp/bxp-design-system-react";
import { apiClient } from "../../utils/clients";
import { FolderIcon, CompanyIcon, ProjectIcon, UserIcon, ServiceAccountIcon, DeleteIcon } from "@netapp/bxp-design-system-react/icons/monochrome";
import styles from "./components/Settings.module.scss";

export const emailRegex = /^(?=.{0,255}$)[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.([a-zA-Z]{2,20})$/;
export const GRID_PAGESIZE = 50;
export const USERS_GRID_THRESHOLD = 300;
export const orgAdminRole = "Organization-Admin";
export const scopeAdminRole = "Scope-Admin";
export const platformViewerRole = "Platform-Viewer";
export const org = "organization";
export const project = "project";
export const ROLES_WITH_HIGHER_ACCESS = [orgAdminRole, scopeAdminRole, platformViewerRole, "SnapCenter System"];

export const getPaginationQuery = (
    pageSize,
    sortState,
    filterState,
    pageIndex,
    defaultFilters = null
) => {
    let query = {};
    if(pageSize) {
        query = {
            limit: pageSize,
            skip: pageSize * pageIndex,
        };
    }
    if (sortState && sortState.column && sortState.sortOrder) {
        query.order_by = `${sortState.column}${sortState.sortOrder === ' desc' ? sortState.sortOrder : ''}`;
    }
    if (defaultFilters) {
        query.filter = defaultFilters;
    }
    return query;
};

export function useAPIQuery(urlObject) {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(urlObject?.url ? true: false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!_.isEmpty(urlObject?.url)) {
            (async () => {
                setIsLoading(true);
                setError(null);
                try {
                    let response;
                    if(urlObject.query) {
                        response = await apiClient.get(urlObject.url, { params: urlObject?.query });
                    } else {
                        response = await apiClient.get(urlObject.url);
                    }
                    const data = JSON.parse(response.data);
                    setData(data);
                }
                catch (err) {
                    setError(err);
                } finally {
                    setIsLoading(false);
                }
            })();
        }
    }, [urlObject]);
    return { data, isLoading, error }
}

export const getFoldersAndProjectsMap = (resources) => {
    const foldersAndProjectsMap = {
        folders: [],
        projects: []
    };
    _.forEach(resources, function (resource) {

        if (!_.isEmpty(resource["childScopes"])) {
            const childScopesFoldersAndProjectsMap = getFoldersAndProjectsMap(resource["childScopes"]);
            foldersAndProjectsMap.folders = [...foldersAndProjectsMap.folders, ...childScopesFoldersAndProjectsMap.folders];
            foldersAndProjectsMap.projects = [...foldersAndProjectsMap.projects, ...childScopesFoldersAndProjectsMap.projects];
        }
        if (resource.resourceType === "folder") {
            foldersAndProjectsMap.folders.push(resource);
        }

        if (resource.resourceType === "project") {
            foldersAndProjectsMap.projects.push(resource);
        }
    });
    return foldersAndProjectsMap;
}

export const isScopeRoleValid = (scope, role) => {
    if (scope?.resourceType === org && role?.name === scopeAdminRole) {
        return false;
    }
    if (scope?.resourceType !== org && role?.name === orgAdminRole) {
        return false;
    }
    return true;
}

/* This function is used to find the scopes for a resource.
 * @param {Object} selectedOrg - selectedOrg data
 * @param {String} scopeId - Id of the resource
 * output - Array of scopes for the resource. Ex: ["gil", "ws1"]  Ex2:  ["gil", "ws2", "test"] }]
 */
export const findPathToScope = (selectedOrg, scopeId, arr = []) => {
    if (!selectedOrg) {
        return [];
    }
    const path = [...arr, { id: selectedOrg.id, name: selectedOrg.name }];
    if (selectedOrg.id === scopeId) {
        return path;
    }
    for (let child of selectedOrg.childScopes) {
        const result = findPathToScope(child, scopeId, path);
        if (result.length) {
            return result;
        }
    }
    return [];
};

// Get existing accessible scope IDs
export const getScopeIds = (selectedUser, allRoles, orgId) => {
    const orgAdminRoleId = allRoles.find(r => r.name === orgAdminRole).id
    if (selectedUser?.roles?.find(id => id === orgAdminRoleId)) {
        const scopeIdMap = {};
        scopeIdMap[orgId] = true;
        return scopeIdMap;
    } else {
        // This list is prepared to disable access to already accessible scopes, 
        // hence platform viewer's org access is skipped as its just a read access
        return _.reduce(selectedUser.tags,
            (acc, tag) => {
                const key = Object.keys(tag)[0];
                if (key === "internal:bxp:projectId" || key === "internal:bxp:folderId") {
                    acc[tag[key]] = true
                }
                return acc;
            },
            {}
        );
    }
}

//Count the unique instances of 'folder' and 'project' types from the inherited scopes.
export const countResourceTypes = (selectedScopes) => {
    if (!selectedScopes) {
        return { folderCount: 0, projectCount: 0 };
    }
    // scopeDetails arrays into one unified array
    const allScopeDetails = _.flatMap(selectedScopes, scope => scope?.scopeDetails ? scope.scopeDetails.map(detail => ({ ...detail, type: scope.type })) : []);
    // Remove duplicates
    const uniqueScopeDetails = _.uniqBy(allScopeDetails, 'id');
    let folderCount = 0;
    let projectCount = 0;
    uniqueScopeDetails.forEach(detail => {
        if (detail.type === 'folder') {
            folderCount++;
        } else if (detail.type === 'project') {
            projectCount++;
        }
    });
    return { folderCount, projectCount };
}

export const scopeTypeIconRenderer = (element) => {
    if (element.resourceType === "organization") {
        return <CompanyIcon className={element?.isSelected ? "" : styles["dark-icon"]} width="20"/>
    } else if (element.resourceType === "folder") {
        return <FolderIcon className={element?.isSelected ? "" : styles["dark-icon"]} width="20"/>
    } else if (element.resourceType === "project") {
        return <ProjectIcon className={element?.isSelected ? "" : styles["dark-icon"]} width="20"/>
    }
    return null
}

export const orgColumnsHeaders = [
    {
        header: "Name",
        accessor: "name",
        id: "name",
        sort: {
            enabled: true,
        },
        Renderer: ({ row }) => {
            return row.resourceType === "organization" ? (
                <div className={styles["colum-cell-treegrid"]}>
                    <CompanyIcon width="20" className={styles["grid-icon"]}></CompanyIcon>
                    <Text
                        {...(row.name?.length > 110 ? { ellipsis: true, title: row.name } : {})}
                        ellipsis
                        style={{
                            marginLeft: "10px",
                            verticalAlign: "super",
                        }}
                    >
                        {row.name}
                    </Text>
                </div>
            ) : row.resourceType === "folder" ? (
                <div className={styles["colum-cell-treegrid"]}>
                    <FolderIcon width="20" className={styles["grid-icon"]}></FolderIcon>
                    <Text
                        {...(row.name?.length > 110 ? { ellipsis: true, title: row.name } : {})}
                        style={{
                            marginLeft: "10px",
                            verticalAlign: "super",
                        }}
                    >
                        {row.name}
                    </Text>
                </div>
            ) : (
                <div className={styles["colum-cell-treegrid"]}>
                    <ProjectIcon width="20" className={styles["grid-icon"]}></ProjectIcon>
                    <Text
                        {...(row.name?.length > 110 ? { ellipsis: true, title: row.name } : {})}
                        style={{
                            marginLeft: "10px",
                            verticalAlign: "super",
                        }}
                    >
                        {row.name}
                    </Text>
                </div>
            );
        },
    },
];
export const RESOURCES_SELECTABLE_MAP = {
    Folder: {
        label: "Folder",
        description:
            "You can use folders to group related projects or create boundaries between them.",
        Icon: FolderIcon,
    },
    Project: {
        label: "Project",
        description:
            "You must create at least one project. Projects are the foundation of NetApp identity access and management.",
        Icon: ProjectIcon,
    }
};

 // Validation for ResourceName and Location
 export const validator = (isOrganization) => yup.object().shape({
    name: yup.string().required("Field is required.").matches(/^[^    ]*$/, "May not contain space").max(300, "Cannot be more than 300 characters."),
    ...(!isOrganization && { parent: yup.array().min(1).required("Field is required") }),
});

export const createFilterExpression = (externalfilter, optionString) => {
    if (!externalfilter) {
      externalfilter = `${optionString}`
    } else {
      externalfilter = `${externalfilter} or ${optionString}`
    }
    return externalfilter
}

export const getSelectedResources = (selectedRows) => {
    const selectedResources = [];
    for (const selectedRow of Object.keys(selectedRows)) {
        if (selectedRows[selectedRow]) {
            selectedResources.push(selectedRow);
        }
    }
    return selectedResources;
}

export const recursiveSearch = (recursiveObjectKey, parentData, searchString, isParentMatched = false) => {
    /**
     * recursiveObjectKey: is the key in the object that you want do the recursive search on.
     * parentData: is the main object that you pass to filter out the search.
     * searchString: is the text that you want to search.
     * !!! isParentMatched: No need to pass this parameter, it is being set and passed internally.
     */
    let matchedChildNodes = []
    _.forEach(parentData, childNode => {
        const childNodeMatched = _.toLower(childNode.name).includes(searchString);
        if(childNode.resourceType === "organization" && childNodeMatched) {
            matchedChildNodes = parentData; 
            return;
        }        
        let childNodesResults = [];
        if (childNode[recursiveObjectKey]?.length > 0) {
            childNodesResults = recursiveSearch(recursiveObjectKey, childNode[recursiveObjectKey], searchString, childNodeMatched);
        }
        if (childNodeMatched || childNodesResults.length > 0) {
            const newNode = { ...childNode };
            if (childNodesResults.length > 0) {
                newNode[recursiveObjectKey] = childNodesResults;
            }
            matchedChildNodes.push(newNode);
        }
    });
    return matchedChildNodes;
}

export const getTableColumns = ({dropdownUsers, onSelectUser, deleteRow, roleMenu}) => {
    return [
        {
            header: "Type",
            accessor: "userType",
            id: "userType",
            width: 100,
            sort: {
                enabled: false
            },
            filter: {
                filter: (value, row, filterState) => {
                    if (filterState.values.serviceAccount && (row.userType === 'serviceAccount' || row.userType === 'agent')) {
                        return true;
                    }
                    if (filterState.values.user && row.userType === 'user') {
                        return true;
                    }
                },
                enabled: false
            },
            Renderer: (user) => {
                const { row: { userType } } = user;
                return (
                    <div className={styles["grid-icon"]}>
                        {userType === "user" && <UserIcon />}
                        {userType ===  "serviceAccount" && <ServiceAccountIcon />}
                    </div>
                );
            }
        },
        {
            header: "Name",
            accessor: "name",
            id: "name",
            width: 300,
            filter: {},
            Renderer: ({ row }) => {
                return (
                    <>
                        {row.name ? (
                            row.name
                        ) : (
                            <FormFieldSelect
                                options={dropdownUsers}
                                isSearchable
                                value={dropdownUsers.find((user) => user.name === row.name)}
                                className={styles["user-select-dropdown"]}
                                onChange={(e) =>
                                    onSelectUser(e, row.id, "name")
                                }
                                placeholder="Select a user"
                            />
                        )}
                    </>
                );
            },
        },
        {
            header: "Role",
            accessor: "role",
            id: "role",
            width:300,
            Renderer: ({ row }) => {
                const gridRoleName = row?.gridRoleName;
                return (
                    <MenuPopover
                        popoverClassName={styles["user-grid-dropdown-menu"]}
                        TriggerButton={(props) => (
                            <DropdownButton
                                {...props}
                                className={styles["nosplitBtn"]}
                                color="secondary"
                                disabled={!row.editableColumn}
                            >
                                {gridRoleName ? <>{gridRoleName}</> : "Select a role"}
                            </DropdownButton>
                        )}
                        menu={() => roleMenu(row)}
                    />
                );
            },
        },
        {
            header: "",
            accessor: "",
            width: 80,
            sort: {
                enabled: false,
            },
            filter: {
                enabled: false,
            },
            Renderer: ({ row }) => {
                return (
                    <div className={styles["delete-row-action-menu"]}>
                        {row.deletableColumn && <Button
                            variant="icon"
                            color="secondary"
                            style={{ width: 14, height: 14 }}
                            onClick={() => deleteRow(row)}
                        >
                            <DeleteIcon />
                        </Button>}
                    </div>
                );
            },
        },
    ];
}

export const getUsersSummary = (users) => {
    let str = "";
    const roleMap = new Map();
    _.forEach(users, (user) => {
        if (!roleMap.has(user.gridRoleName)) {
            roleMap.set(user.gridRoleName, 1);
        } else {
            let userIds = roleMap.get(user.gridRoleName);
            userIds++;
            roleMap.set(user.gridRoleName, userIds);
        }
    })
    
    roleMap.forEach( (value, key) => {
        if(!str.length) {
            str = `${value} ${key} `
        } else {
            str = `${str} | ${value} ${key}`
        }
    });
    return str ? str : "Action needed"
}

export const getRoleWithHigherAccess = (roles) => {
    let selectedRole;
    _.forEach(ROLES_WITH_HIGHER_ACCESS, function (roleName) {
        const selectedUserRole = roles.find(role => role.name === roleName);
       if(!_.isEmpty(selectedUserRole)) {
            selectedRole = selectedUserRole;
            return false;
       } 
    })
    return selectedRole
}

export const getRoleName = (allRoles, roleIds) => {
    const selectedRoleNames = _.map(roleIds, function (roleId) {
        const selectedUserRole = allRoles.find(userRole => userRole.id === roleId);
        return { name: selectedUserRole.name, id: selectedUserRole.id};
    })
    return getRoleWithHigherAccess(selectedRoleNames);
}

export const getRoleId = (allRoles, roleName) => {
    const selectedRole = _.filter(allRoles, function (role) {
        return role.gridRoleName === roleName
    })
    return selectedRole;
};

export const updateUserToRoleMap = (userRoleMap, roleObj) => {
    const clonedRoleMap = new Map(userRoleMap);
    // if userId and roleId are undefined then return pass parms userRoleMap
    if(_.isUndefined(roleObj.userId) || _.isUndefined(roleObj.roleId)){
        return clonedRoleMap;
    }
    // Add if initialRoleId is undefined
    if(_.isUndefined(roleObj.initialRoleId)) {
        const users =  clonedRoleMap.get(roleObj.roleId) || [];
        clonedRoleMap.set(roleObj.roleId, [...users, {
            userId: roleObj.userId,
        }]);
        return clonedRoleMap;
    }
    // Updated if initialRoleId is defined
    for (let roleUsersArray of userRoleMap) { 
        const [role, users] = roleUsersArray;
        if(_.some(users, {userId: roleObj.userId})) {
            _.remove(users, {userId: roleObj.userId});
            if(users.length === 0) {
                clonedRoleMap.delete(role);
            } else {
                clonedRoleMap.set(role, users);
            }
        }
    }
    if(roleObj.initialRoleId !==roleObj.roleId ) {
        if(clonedRoleMap.has(roleObj.roleId)) {
            const users = clonedRoleMap.get(roleObj.roleId) || [];
            users.push({
                userId: roleObj.userId
            })
            clonedRoleMap.set(roleObj.roleId, users);
        } else {
            clonedRoleMap.set(roleObj.roleId, [
                {
                    userId: roleObj.userId,
                },
            ]);
        }
    }
    return clonedRoleMap;
}

export const filteredTreeResults = (filterKey, optionArray) => {
    if (!_.isUndefined(filterKey) && filterKey.length > 2 && filterKey !== '') {
        const lowerCasedFilter = _.toLower(filterKey);

        const filteredData = optionArray[0].childScopes?.length ?
            recursiveSearch('childScopes', [optionArray[0]], lowerCasedFilter) : [];
        return filteredData.length ? filteredData : {};
    }
    return optionArray;
}