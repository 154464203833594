import React, { useState } from "react";
import { Modal, ModalHeader, ModalFooter, ModalContent, Button, Text, Heading, Checkbox, ExternalLink } from "@netapp/bxp-design-system-react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'

const infoGridCss = css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
`;

const itemContent = css`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const externalLink = css`
    margin: auto 0 10px;
`;

const modalConentCss = css`
    height: 500px;
    overflow: hidden;
`;

const modalInnerConent = css`
    scrollbar-color: var(--scroller) var(--scroller-track);
    scrollbar-width: thin;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
`;

const textHeadingDescription = css`
  width: 100%;
  max-width: 205px;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

function WhatsNew ({ whatsNewConfig, handleCheckboxChange }) {
    const [isWhatsNew, setWhatsNew] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const close = () => {
        if(isChecked) {
            handleCheckboxChange(isChecked)
        }
        setWhatsNew(true);
    };

    if (!isWhatsNew) {
        return <Modal>
            <ModalHeader>
                What’s new
            </ModalHeader>
            <ModalContent css={modalConentCss}>
                <div css={modalInnerConent}>
                    <Text>
                        We've simplified some concepts and added new functionality to let you fine tune
                        <Text style={{ marginBottom: 16 }}>
                            access to your organization's resources.
                        </Text>
                    </Text>
                    <section css={infoGridCss}>
                        {
                            whatsNewConfig.map((data, i) => (
                                <article key="i" css={itemContent}>
                                    {data.img}
                                    <div>
                                        <Heading level={3} style={{ marginBottom: 4 }}>
                                            <div css={textHeadingDescription}>{data.title}</div>
                                        </Heading>
                                        <Text css={textHeadingDescription}>{data.content}</Text>
                                    </div>
                                    <ExternalLink variant="text" href="http://netapp.com" includeArrow={true} css={externalLink}>
                                        Learn More
                                    </ExternalLink>
                                </article>
                            ))
                        }

                    </section>
                </div>
                <div style={{ position: "relative" }}>
                    <Checkbox
                        checked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                        style={{ position: "absolute", top: 5 }}
                    >
                        Don’t show again
                    </Checkbox>
                </div>
            </ModalContent>
            <ModalFooter>
                <Button onClick={close}>Close</Button>
            </ModalFooter>
        </Modal>
    }
    return ""
}

export default WhatsNew;