import useIframe from "utils/useIframe"
import useIframeV2 from "utils/useIframeV2"
import styles from "./ServiceIframe.module.scss";
import React, { useEffect, useState, useMemo } from "react";
import externals from "utils/externals";
import useCurrentService from "utils/useCurrentService";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, Redirect, Switch, Route } from "react-router-dom";
import { MainLoader, TabHeader, TabLinks } from "@netapp/bxp-design-system-react";
import classNames from "classnames";
import _ from "lodash";
import DefaultRoute from "modules/app/components/DefaultRoute";
import { useLocation } from "react-router";

const GlobalSearchMask = () => {
    const dispatch = useDispatch();

    return <div className={styles["mask"]} onClick={() => {
        dispatch({type: "GLOBAL-SEARCH/CLOSE"})
    }}/>
}

/**
 * V1 is deprecated, dont update it, any new integrations must be on V2
 */
const RenderV1 = ({ version, iframeKey, isThirdParty, trackKey, setWizardOpen, isWizardOpen }) => {
    const { iframeRef, src } = useIframe({ iframeUrl: externals.serviceIframes[iframeKey], isThirdParty, trackKey, setWizardOpen })

    return <iframe title="service" src={src} ref={iframeRef} className={classNames(styles['base'], { [styles['sub-tab']]: version === "childIframe/v1" && !isWizardOpen })}/>
};

const RenderV2 = ({ iframeKey, isThirdParty, trackKey, currentService }) => {
    const activeFeatures = useSelector(state => state.features.active);
    const subTabs = useMemo(() => {
        const featuresById = _.keyBy(currentService.uiIntegration?.features, "id");
        return _.filter(currentService.uiIntegration?.subTabs, subTab => {
            return subTab.featureId ? activeFeatures[subTab.featureId] : true
        }).map(subTab => {
            return {
                to: subTab.to,
                label: subTab.label,
                featureTargets: subTab.featureId ? featuresById[subTab.featureId]?.targets : undefined
            }
        });
    }, [activeFeatures, currentService])
    const { isInitialized, iframeRef, src } = useIframeV2({ iframeUrl: externals.serviceIframes[iframeKey], isThirdParty, trackKey, subTabs })

    return <>
        {!isInitialized && <MainLoader/>}
        <iframe title="service" src={src} ref={iframeRef} className={classNames(styles['base'])} style={{ opacity: !isInitialized ? 0 : undefined}}/>
    </>
}

const ShowIframe = ({ currentService, subTabLabel, isThirdParty, isWizardOpen = false, setWizardOpen = _.noop, iframeKey: _iframeKey, version }) => {
    const isGlobalSearchOpen = useSelector(state => state.globalSearch?.isOpen);
    const location = useLocation();

    let iframeKey = null;
    if(_iframeKey) {
        iframeKey = _iframeKey;
    } else if(currentService.uiIntegration?.stageIntegrations) {
        const servicePath  = _.replace(location.pathname, currentService.service.to, "");
        // checks if any sub tab path exists
        let rootIntegration = _.find(currentService.uiIntegration.stageIntegrations, { path: servicePath && servicePath.trim().length === 0 ? "/": servicePath});
        if(!rootIntegration) {
            // Navigates to the default path if not defined.
            rootIntegration = _.find(currentService.uiIntegration.stageIntegrations, { path: "/"});
        }
        if(rootIntegration) {
            iframeKey = rootIntegration.endpointId;
            version = rootIntegration.renderType;
        }
    } else {
        iframeKey = currentService.key;
    }

    console.log(`IframeKey: `, iframeKey, "version: ", version, externals.serviceIframes[iframeKey]);
    console.log(externals);

    const trackKey = subTabLabel ? `${currentService.key}.${subTabLabel}` : currentService.key;
    const { iframeRef, src } = useIframe({ iframeUrl: externals.serviceIframes[iframeKey], isThirdParty, trackKey, setWizardOpen })

    useEffect(() => {
        return () => {
            setWizardOpen(false);
        }
    }, [setWizardOpen]);

    if(!version) {
        version = "iframe/v1";
    }

    if (!externals.serviceIframes[iframeKey]) {
        return <div>
            Invalid Configuration -> No Path for {iframeKey}
        </div>
    }

    return <>
        {isGlobalSearchOpen && <GlobalSearchMask/>} {/* Need to add mask over iframe otherwise the app doesnt track clicks when global search is open */}
        {(version === "iframe/v2" || version === "childIframe/v2") && <RenderV2 currentService={currentService} iframeKey={iframeKey} isThirdParty={isThirdParty} trackKey={trackKey}/>}
        {(version === "iframe/v1" || version === "childIframe/v1") && <RenderV1 iframeKey={iframeKey} version={version} isThirdParty={isThirdParty} trackKey={trackKey} setWizardOpen={setWizardOpen} isWizardOpen={isWizardOpen}/>}
    </>
};

export const RenderSubIframe = ({ legacySubService, currentService, iframeKey, isWizardOpen, setWizardOpen, featureTargets, version, subTabLabel }) => {
    return <DefaultRoute featureTargets={featureTargets} subService={legacySubService} needHeader={false}><ShowIframe subTabLabel={subTabLabel} version={version} iframeKey={iframeKey} currentService={currentService} isThirdParty={false} isWizardOpen={isWizardOpen} setWizardOpen={setWizardOpen}/></DefaultRoute>
}

const ParentService = React.memo(({ currentService }) => {
    const [isWizardOpen, setWizardOpen] = useState(false);
    const activeFeatures = useSelector(state => state.features.active);

    const subServicesByTo = _.keyBy(currentService.service.subServices, "to");

    const stageIntegrationsByPath = _.keyBy(currentService.uiIntegration?.stageIntegrations, "path");

    const featuresById = _.keyBy(currentService.uiIntegration?.features, "id");

    const subTabs = _.filter(currentService.uiIntegration?.subTabs, subTab => {
        if(subServicesByTo[subTab.to]){ //legacy way of checking feature status
            return activeFeatures[`${currentService.key}.${subServicesByTo[subTab.to].key}`];
        }

        const stageIntegration = stageIntegrationsByPath[subTab.to];
        return !!stageIntegration && (subTab.featureId ? activeFeatures[subTab.featureId] : true);
    }).map(subTab => {
        const legacySubService = subServicesByTo[subTab.to];
        const legacyIframeKey = legacySubService?.iframeKey || legacySubService?.key;

        return {
            to: subTab.to,
            label: subTab.label,
            legacySubService,
            version: stageIntegrationsByPath[subTab.to]?.version || "childIframe/v1",
            iframeKey: stageIntegrationsByPath[subTab.to]?.endpointId || legacyIframeKey,
            featureTargets: subTab.featureId ? featuresById[subTab.featureId]?.targets : undefined
        }
    });

    return <>
        {!isWizardOpen && <TabHeader logo={currentService.logo} label={currentService.name}>
            <TabLinks>
                {subTabs.map(({ to, label }) => {
                    return <NavLink key={label} to={`${currentService.service.to}${to}`}>{label}</NavLink>
                })}
            </TabLinks>
        </TabHeader>}
        <Switch>
            {subTabs.map(({ to, label, legacySubService, iframeKey, featureTargets, version }) => {
                return <Route path={`${currentService.service.to}${to}`} key={label}>
                    <RenderSubIframe subTabLabel={label} version={version} iframeKey={iframeKey} legacySubService={legacySubService} featureTargets={featureTargets} currentService={currentService} isWizardOpen={isWizardOpen} setWizardOpen={setWizardOpen}/>
                </Route>
            })}
            <Redirect to={`${currentService.service.to}${subTabs[0].to}`}/>
        </Switch>
    </>
})

export default React.memo(() => {
    
    const currentService = useCurrentService();
    const isThirdParty = currentService.service.isThirdParty;
    const dispatch = useDispatch();
    const selectedAgentId = useSelector(state => state.tenancy.selectedAgentId);
    const openAddConnector = currentService.service.needsConnector && !selectedAgentId;
    const saasEnabled = useSelector(state => state.tenancy.selectedAccount.isSaas)
    const { thirdPartyToken } = useSelector(state => state.auth);

    useEffect(() => {
        if (isThirdParty) {
            dispatch({
                type: "AUTH:THIRD-PARTY-AUDIENCE"
            })
        }
    }, [isThirdParty, dispatch])

    if (openAddConnector && (saasEnabled || process.env.REACT_APP_IS_SAAS !== "true")) {
        return <Redirect to={{ pathname: window.location.pathname, hash: "#addConnector" }}/>;
    } else if (isThirdParty && !thirdPartyToken) {
        return <MainLoader/>
    } else if (currentService.service.isParent) {
        return <ParentService key={currentService.key} currentService={currentService}/>
    } else {
        return <DefaultRoute><ShowIframe key={currentService.key} currentService={currentService} isThirdParty={isThirdParty}/></DefaultRoute>
    }
})
