import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./../MainHeader.module.scss";
import SlidingWidget, { CaptionButton, SlidingWidgetFooter } from "./SlidingWidget"
import { SearchWidget, TreeList } from "@netapp/bxp-design-system-react";
import classNames from "classnames";
import _ from "lodash";

const OrganizationMenuFooter = ({ activeOrg, selectedOrg }) => {
    const dispatch = useDispatch();

    return <SlidingWidgetFooter onSwitch={() => {
        if (activeOrg === selectedOrg) {
            return;
        }

        dispatch({
            type: "TENANCYV4:SET-ORG",
            payload: {
                orgId: selectedOrg.id
            }
        });
    }} onClose={() => {
        dispatch({
            type: "MENU:CLOSE-WIDGET"
        })
    }} />
};

const MenuContent = React.memo(({ setSelectedOrg }) => {
    const activeOrg = useSelector(state => state.tenancy.selectedOrg);
    const orgs = useSelector(state => state.tenancy.orgs) || [];
    const [filteredOrgs, setFilteredOrgs] = useState(orgs);

    const filterOrgs = (value) => {
        setFilteredOrgs(value == '' ? [...orgs] : _.filter(orgs, (org) =>_.lowerCase(org?.name)?.includes(_.lowerCase(value))))
    }

    useEffect(() => {
        setFilteredOrgs(orgs)
    }, [orgs]);

    const onChangeEvent = (org) => {
        setSelectedOrg(org[0]);
    }

    const customStyles = {
        treeItemBase: {
            height: '55px'
        },
        treeListBase: {
            maxHeight: 'unset'
        }
    }

    return <div style={{ paddingTop: 20 }} className={classNames(styles['org-menu-content'])}>
        <SearchWidget placeholder="Search Organization" alwaysOpen className={styles['org-search-widget']} setFilter={filterOrgs} />
        <div style={{margin: "0px 40px"}}>
            <TreeList
                treeData={filteredOrgs}
                menuType={'singleRadio'}
                uniqueIdentifier={'id'}
                labelKey={'name'}
                bottomBorder={true}
                selectedValues={[activeOrg]}
                onChange={onChangeEvent}
                customStyles={customStyles}/>
        </div>
    </div>
});

export default () => {
    const activeOrg = useSelector(state => state.tenancy.selectedOrg);
    const [selectedOrg, setSelectedOrg] = useState(activeOrg);
    const orgName = activeOrg?.name || "N/A";
    return <SlidingWidget
        widgetKey="organization"
        menuTitle="Organization"
        canClose={() => {
            return false;
        }}
        triggerClassName={styles['caption-button']}
        triggerActiveClassName={[styles['caption-button-active']]}
        TriggerButton={(props) => <CaptionButton title="Organization" value={orgName} {...props} />}
        MenuFooter={process.env.REACT_APP_IS_SAAS !== "true" ? undefined : () => <OrganizationMenuFooter activeOrg={activeOrg} selectedOrg={selectedOrg} />}
    >
        <MenuContent setSelectedOrg={setSelectedOrg} selectedOrg={selectedOrg} />
    </SlidingWidget>
};